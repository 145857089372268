import React from 'react';
import './App.css';

import Axios from 'axios';

import Config from './Config';
import './icons/css/ht-icons.css';
import './fonts/lexend-deca.css';

class App extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      currentValue: '',
      searchedValue: null,
      likes: 0,
      dislikes: 0,
      newWord: false
    };

    this.fieldRef = React.createRef();

  }

  componentDidMount() {

    this.fieldRef.current.focus();

  }

  render() {
    
    return (
      <div className="App">
        <h1>charar8</h1>
        <div className="search-box-container">
          <form className="search-form" method="post" action="#" onSubmit={this.onSubmit.bind(this)}>
            <input className="search-box" type="text" value={this.state.currentValue} onChange={this.onValueChange.bind(this)} ref={this.fieldRef}/>
            <button className="search-button" onClick={this.onSubmit.bind(this)}><span className="icon-right-bold" /></button>
          </form>
          { this.state.searchedValue !== null &&
            <React.Fragment>
              <h2>
                { this.state.searchedValue }
              { this.state.newWord && 
                <sup>FRESH !</sup>
              }
              </h2>
              <div className="results">
                <button className="result-button likes" onClick={ this.like.bind(this, this.state.searchedValue) }><span className="icon-thumbs-up icon" /> { this.state.likes }</button>
                <button className="result-button dislikes" onClick={ this.dislike.bind(this, this.state.searchedValue) }><span className="icon-thumbs-down icon" /> { this.state.dislikes}</button>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    );

  }

  onValueChange(e) {

    this.setState({
      currentValue: e.target.value.replace(/^([A-Z]{2})( *)(\d+)$/i, (match, p1, p2, p3, offset, string) => {
        return p1.toUpperCase() + '' + p3;
      })
    });

  }

  like(value) {

    Axios.post(Config.APIUrl + '/like', {
      value: value
    }).then((result) => {

      this.setState({
        searchedValue: result.data.value,
        likes: parseInt(result.data.likes),
        dislikes: parseInt(result.data.dislikes)
      });

    }).catch(() => {

      this.setState({
        searchedValue: value,
        likes: this.state.likes + 1,
        dislikes: this.state.dislikes
      });

    });

  }

  dislike(value) {

    Axios.post(Config.APIUrl + '/dislike', {
      value: value
    }).then((result) => {

      this.setState({
        searchedValue: result.data.value,
        likes: parseInt(result.data.likes),
        dislikes: parseInt(result.data.dislikes)
      });

    }).catch(() => {

      this.setState({
        searchedValue: value,
        likes: this.state.likes,
        dislikes: this.state.dislikes + 1
      });

    });

  }

  onSubmit(e) {

    e.preventDefault();

    this.setState({
      currentValue: this.state.currentValue.trim()
    }, () => {

      Axios.post(Config.APIUrl + '/rating', {
        value: this.state.currentValue
      }).then((result) => {

      this.setState({
          searchedValue: result.data.value,
          likes: parseInt(result.data.likes),
          dislikes: parseInt(result.data.dislikes),
          newWord: result.data.newWord
        });

      }).catch(() => {

        this.setState({
          searchedValue: this.state.currentValue,
          likes: 0,
          dislikes: 0,
        });

      })

    });

    return false;

  }


}

export default App;
