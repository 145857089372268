class Config{

  static get APIUrl() {

    return "https://fri-project.fullrange.ch:8084/api";

  }

}

export default Config;